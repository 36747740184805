<script setup lang="ts">
defineProps<{
	active?: boolean;
}>();

const imgConfig = {
	sector: {
		img: "sectors"
	},
	border: {
		img: "borders"
	},
	button: {
		img: "buttons"
	},
	arrow: {
		img: "arrows"
	}
};
</script>

<template>
	<div class="spinner-wheel" :class="{ animated: active }">
		<NuxtImg
			v-for="(element, key) in imgConfig"
			:key="key"
			:src="`/nuxt-img/wheel/mob-menu/${element.img}.png`"
			:class="key"
			format="webp"
			alt="Wheel element"
		/>
	</div>
</template>

<style lang="scss" scoped>
.spinner-wheel {
	display: flex;
	position: absolute;
	width: 100px;
	height: 100px;
	border-radius: 8px 0 0 8px;
	cursor: pointer;

	&.animated {
		& .sector {
			animation: animation__sector 16.5s infinite;
		}

		& .button {
			animation: animation__button 16.5s infinite linear;
		}
	}

	& .sector {
		position: absolute;
		right: 6px;
		top: 6px;
		width: 87px;
	}

	& .border {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 100px;
	}

	& .arrow {
		position: absolute;
		right: 42%;
		top: 2px;
		width: 15px;
	}

	& .button {
		position: absolute;
		right: 31%;
		top: 33%;
		width: 38px;
	}

	.fortune-wheel-label {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: var(--сirebon);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 5%;
		left: 10%;
	}
}

@keyframes animation__sector {
	0%,
	100% {
		transform: rotate(0);
	}
	34% {
		transform: rotate(730deg);
	}
	39.3939%,
	99.9999% {
		transform: rotate(720deg);
	}
}
@keyframes animation__button {
	0%,
	10%,
	20%,
	30%,
	39.3939%,
	100% {
		transform: scale(1);
	}
	5%,
	15%,
	25%,
	35% {
		transform: scale(1.2);
	}
}
</style>
